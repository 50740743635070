<template>
	<EcommerceShoppingCart
		v-qa="'user-section-zyroecommerceshoppingcart'"
		:is-shopping-cart-open="isShoppingCartOpen"
		:is-loading="isCheckoutLoading"
		:header-height="headerHeight"
		:is-header-sticky="isHeaderSticky"
		:is-nav-hidden="isNavHidden"
		:translations="ecommerceTranslations"
		:language="language"
		:site-id="siteId"
		:quantified-cart-items-list="quantifiedCartItemsList"
		:shopping-cart-items="shoppingCartItems"
		:variants-quantity="variantsQuantity"
		@checkout-button-click="initCheckout"
		@close-shopping-cart="setShoppingCartOpen(false)"
		@set-shopping-cart-items="setShoppingCartItems"
	/>
</template>

<script lang="ts" setup>
import EcommerceShoppingCart from '@zyro-inc/site-modules/components/ecommerce/EcommerceShoppingCart.vue';
import { getIsInIframe } from '@zyro-inc/site-modules/utils/getIsInIframe';
import { isAppPrerendering } from '@zyro-inc/site-modules/utils/prerenderingFlags';
import { useEcommerceModal } from '@zyro-inc/site-modules/components/ecommerce/modals/useEcommerceModal';
import { useEcommerce } from '@zyro-inc/site-modules/components/ecommerce/useEcommerce';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { onMounted } from 'vue';

type Props = {
	headerHeight?: number,
	ecommerceTranslations: Record<string, string>,
	language: string,
	isHeaderSticky?: boolean,
	isNavHidden?: boolean,
	isInPreviewMode?: boolean
}

const props = withDefaults(defineProps<Props>(), {
	headerHeight: 0,
	isHeaderSticky: false,
	isNavHidden: false,
	isInPreviewMode: false,
});

const { siteId } = useSiteGlobal();
const {
	quantifiedCartItemsList,
	isShoppingCartOpen,
	isCheckoutLoading,
	shoppingCartItems,
	variantsQuantity,
	fetchCartProducts,
	setShoppingCartOpen,
	setShoppingCartItems,
	setIsCheckoutLoading,
} = useEcommerceGlobal();
const { openEcommerceModal } = useEcommerceModal();
const { initiateCheckout } = useEcommerce();

const loadProducts = async () => {
	if (!isAppPrerendering) {
		await fetchCartProducts();
	}
};

const initCheckout = async () => {
	if (getIsInIframe() || props.isInPreviewMode) {
		openEcommerceModal('EcommerceMessageButtonDisabled');

		return;
	}

	if (isCheckoutLoading.value) {
		return;
	}

	setIsCheckoutLoading(true);

	await initiateCheckout(shoppingCartItems.value).then(() => {
		setShoppingCartOpen(false);
		setIsCheckoutLoading(false);
	});
};

onMounted(async () => {
	await loadProducts();
});
</script>
