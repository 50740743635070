import {
	computed,
	ref,
} from 'vue';

import {
	initializeChatbot,
	messageChatbot,
	isChatbotAvailable,
} from '@zyro-inc/site-modules/api/ChatbotApi';
import { getDomainWithoutWWWPrefix } from '@zyro-inc/site-modules/utils/domainUtils';
import { removeHtmlTags } from '@zyro-inc/site-modules/utils/removeHtmlTags';

import {
	ChatbotConversationMessage,
	ChatbotRoles,
	ChatbotTexts,
	EcommerceProduct,
	EcommerceRegionShippingOptions,
} from '@zyro-inc/site-modules/types';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';
import {
	CONVERSATION_ID_STORAGE_KEY,
	PAGE_TYPE_PRIVATE,
} from '@zyro-inc/site-modules/constants';
import { getVariantPrice } from '@zyro-inc/site-modules/utils/currency';

export const useSiteAiAssistant = (isInPreviewMode: boolean) => {
	const {
		siteId,
		blocks,
		pages,
		elements,
	} = useSiteGlobal();
	const products = ref<EcommerceProduct[]>([]);
	const regionShippingOptions = ref<EcommerceRegionShippingOptions>([]);
	const elementsArr: Array<{ content: string }> = Object.values(elements.value);
	const pageContent = elementsArr.map((element) => element.content).filter(Boolean);

	const areLimitsReached = ref<boolean | null>(null);
	const isAiAssistantVisible = ref<boolean>(isInPreviewMode);

	const sanitizedContent = pageContent.map(removeHtmlTags);

	const {
		getShippingData,
		getProductsByIds,
	} = useEcommerceGlobal();

	const uniqueProductIds = computed<string[]>(() => {
		const blocksArr: Array<{ type: string, productIds: string[] }> = Object.values(blocks.value);

		const allProductIds = blocksArr.filter(({ type }) => type === 'BlockEcommerceProductList').flatMap(({ productIds }) => productIds);

		return [...new Set(allProductIds)];
	});

	const context = computed(() => {
		const domainNameWithoutWww = getDomainWithoutWWWPrefix(window.location.hostname);

		const pagesArr = Object.values(pages.value) as Array<{ slug?: string, name?: string, type: string }>;
		const preparedPages = pagesArr.filter((page) => {
			if (page.type === PAGE_TYPE_PRIVATE) return false;

			return page?.name || page?.slug;
		}).map(({
			name,
			slug,
		}) => ({
			name,
			slug,
		}));

		const preparedProducts = products.value.map((product) => ({
			title: product.title,
			subtitle: product.subtitle,
			description: product.description,
			variants: product.variants.map((variant) => ({
				title: variant.title,
				prices: variant.prices.map((price) => ({
					amount: getVariantPrice(price, product.type?.value),
					currency: price.currency.name,
				})),
			})),
		}));

		const preparedRegionShippingOptions = regionShippingOptions.value
			.map(({
				name,
				shippingOptions,
			}) => ({
				name,
				shippingOptions: shippingOptions.filter((option) => !option.admin_only)
					.map((option) => ({
						provider_id: option.provider_id,
						name: option.name,
					})),
			}));

		return {
			initialPageContent: sanitizedContent,
			domain: domainNameWithoutWww,
			pages: preparedPages,
			products: preparedProducts,
			regionShippingOptions: preparedRegionShippingOptions,
		};
	});

	const conversationHistory = ref<ChatbotConversationMessage[]>([]);
	const isChatbotResponding = ref(false);
	const isChatbotRestarting = ref(false);
	const conversationId = ref<string>();
	const isChatbotInitialized = ref(false);

	const initialChatbotMessage = computed<ChatbotConversationMessage[]>(() => ([
		{
			role: ChatbotRoles.assistant,
			content: 'Welcome! 👋\n\nHow can I assist you today?\n\n<p class="chatbot-message__note">Note: I\'m still learning and may provide incorrect information.</p>',
		},
	]));

	const chatbotTexts: ChatbotTexts = {
		main: {
			title: 'AI assistant',
			button: 'Ask Assistant',
			questionInputPlaceholder: 'Type your question',
			tooltipReset: 'Reset',
			tooltipClose: 'Close',
			disclaimer: isInPreviewMode ? 'AI assistant isn\'t available in preview mode. Update your website and visit it to test the AI assistant.' : undefined,
		},
		modalRestart: {
			title: 'Clear chat',
			description: 'After clearing history you won’t be able to access previous chats.',
			cancelButton: 'Cancel',
			confirmButton: 'Confirm',
		},
	};

	const chatbotInitialize = async (initialConversationId?: string) => {
		if (isInPreviewMode) {
			conversationHistory.value = initialChatbotMessage.value;

			return;
		}

		try {
			if (!initialConversationId) {
				products.value = await getProductsByIds(uniqueProductIds.value);

				regionShippingOptions.value = await getShippingData();
			}

			const data = await initializeChatbot({
				siteId: siteId.value,
				context: initialConversationId ? {} : context.value,
				conversationId: initialConversationId,
			});

			conversationId.value = data.conversationId;

			conversationHistory.value = data.history.length ? [
				...initialChatbotMessage.value,
				...data.history,
			] : initialChatbotMessage.value;

			window.sessionStorage.setItem(CONVERSATION_ID_STORAGE_KEY, data.conversationId);
			isChatbotInitialized.value = true;
		} catch (error) {
			console.error(error);

			conversationHistory.value = [
				{
					role: ChatbotRoles.system,
					content: 'AI Assistant is not available at this moment. Please try again later.',
				},
			];
		}
	};

	const chatbotStart = async () => {
		if (isChatbotInitialized.value) {
			return;
		}

		const initialConversationId = window.sessionStorage.getItem(CONVERSATION_ID_STORAGE_KEY) || undefined;

		await chatbotInitialize(initialConversationId);
	};

	const chatbotRespond = async (content: string) => {
		if (isChatbotResponding.value || !conversationId.value) {
			return;
		}

		conversationHistory.value = [
			...conversationHistory.value,
			{
				role: ChatbotRoles.user,
				content,
			},
			{
				role: ChatbotRoles.assistant,
			},
		];

		isChatbotResponding.value = true;

		try {
			const data = await messageChatbot({
				siteId: siteId.value,
				conversationId: conversationId.value,
				content,
			});

			conversationHistory.value.pop();

			conversationHistory.value = [
				...conversationHistory.value,
				{
					role: ChatbotRoles.assistant,
					content: data.message,
				},
			];
		} catch (error) {
			console.error(error);

			conversationHistory.value.pop();

			let message: ChatbotConversationMessage = {
				role: ChatbotRoles.system,
				content: 'AI Assistant is not available at this moment. Please try again later.',
			};

			// any - because different format error objects can possibly be returned from the backend
			if ((error as { cause: any })?.cause?.message?.includes('Chat message limit reached')) {
				window.sessionStorage.removeItem(CONVERSATION_ID_STORAGE_KEY);

				message = {
					role: ChatbotRoles.assistant,
					content: "You've sent me the maximum number of questions. Reload this page if you'd like to ask another question.",
				};
			}

			conversationHistory.value = [
				...conversationHistory.value,
				message,
			];
		}

		isChatbotResponding.value = false;
	};

	const chatbotRestart = async () => {
		isChatbotRestarting.value = true;

		await chatbotInitialize();

		isChatbotRestarting.value = false;
	};

	const checkAiAssistantLimits = async () => {
		if (isInPreviewMode) {
			return;
		}

		try {
			const data = await isChatbotAvailable({
				siteId: siteId.value,
			});

			areLimitsReached.value = data.areLimitsReached;
			isAiAssistantVisible.value = data.areLimitsReached === false;
		} catch (error) {
			console.error(error);
		}
	};

	return {
		chatbotStart,
		chatbotRespond,
		chatbotRestart,
		conversationHistory,
		isChatbotResponding,
		isChatbotRestarting,
		chatbotTexts,
		checkAiAssistantLimits,
		areLimitsReached,
		isAiAssistantVisible,
	};
};
